import React, { useEffect } from "react";
import Routes from "./Routes";
import { currentLoader, changeLoader } from "./redux/reducers/loader";
import { useSelector, useDispatch } from "react-redux";
import Snackbars from "./utils/global/Snackbars";
import { setSnackbar, snackObj } from "./redux/reducers/snackbar";
import { updateAuth } from "./redux/reducers/loginData";
import Loaders from "./utils/global/Loders";
import * as CUSTOM from "./utils/helper/custom";
import { changeTrigger, currentTrigger } from "redux/reducers/trigger";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { getUserData} from "redux/reducers/user";
import TagManager from "react-gtm-module"
const tagManagerArgs = {
  gtmId: "GTM-KL5ZLZTC"
}
TagManager.initialize(tagManagerArgs)

const iPhoneInput = {
  styleOverrides: {
    root: {
      "*": {
        "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
        "-moz-user-select": "text !important" /* Firefox 2+ */,
        "-ms-user-select": "text !important" /* IE 10+ */,
        "user-select": "text !important" /* Standard syntax */,
      },
    },
  },
};

const muiTheme = createTheme({
  components: {
    MuiTextField: iPhoneInput,
    MuiInput: iPhoneInput,
    MuiFilledInput: iPhoneInput,
    MuiOutlinedInput: iPhoneInput,
  },
});

const langKey = "jsahdjkanbn";
const defaultLang = "ar";
const supportedLangs = ["ar", "en"];
const langValues = {
  "sd5s42s3ad2sa1d3iu748923DSF":"ar",
  "sd542s3ad2sa1d3iu748923DSF":"en"
};

function App() {
  const { i18n } = useTranslation();
  const loading = useSelector(currentLoader);
  const triggerValue = useSelector(currentTrigger);
  const userData = useSelector(getUserData);
  const snackbarObj = useSelector(snackObj);
  const dispatch = useDispatch();

  useEffect(() => {
    if (snackbarObj?.snackbarOpen) {
      setTimeout(() => {
        dispatch(
          setSnackbar({
            ...snackbarObj,
            snackbarOpen: false,
          })
        );
      }, 2000);
    }
  }, [snackbarObj?.snackbarOpen]);

  useEffect(() => {
    if (triggerValue == "loggedin" || triggerValue == "profileUpdated") {
      let loginUserData = CUSTOM.loginData();
      if (triggerValue == "loggedin") {
        if (userData?.is_new_user == true) {
          TagManager.dataLayer({
            dataLayer: {
              event: "sign_up",
              user_id: loginUserData?.id,
              first_name: loginUserData?.firstName,
              last_name: loginUserData?.lastName,
              mobile_no: loginUserData?.mobileNo
            }
          })
        } else if (userData?.is_new_user == false) {
          TagManager.dataLayer({
            dataLayer: {
              event: "login",
              user_id: loginUserData?.id,
              first_name: loginUserData?.firstName,
              last_name: loginUserData?.lastName,
              mobile_no: loginUserData?.mobileNo
            }
          })
        }
      }
      dispatch(updateAuth(loginUserData));
      dispatch(changeLoader(false));
    }
  }, [triggerValue]);

  useEffect(() => {
    if (triggerValue == "closepopup") {
      document.body.classList.remove("overflow-hidden");
      const headerBox = document.getElementById("header-box");
      if (headerBox) {
        headerBox.classList.remove("relative");
      }
      dispatch(changeLoader(false));
    }
  }, [triggerValue]);

  useEffect(() => {
    if (triggerValue == "addpopup") {
      document.body.classList.add("overflow-hidden");
      const headerBox = document.getElementById("header-box");
      if (headerBox) {
        headerBox.classList.add("relative");
      }
      dispatch(changeTrigger(""));
    }
  }, [triggerValue]);

  useEffect(() => {
    document.body.classList.remove("overflow-hidden");
    const headerBox = document.getElementById("header-box");
    if (headerBox) {
      headerBox.classList.remove("relative");
    }
  }, []);

  useEffect(() => {
    dispatch(updateAuth(CUSTOM.loginData()));
    dispatch(changeLoader(false));
  }, []);

  useEffect(() => {
    if (loading === "removeBodyClass") {
      const headerBox = document.getElementById("header-box");
      if (headerBox) {
        document.body.classList.remove("overflow-hidden");
        headerBox.classList.remove("relative");
        headerBox.classList.remove("relative");
        dispatch(changeLoader(false));
      }
    }
  }, [loading]);

  //change language
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    let lang = langValues[localStorage.getItem(langKey)];
    if (!lang || !supportedLangs.includes(lang)) {;
      localStorage.setItem("jsahdjkanbn", "sd5s42s3ad2sa1d3iu748923DSF");
      changeLanguage(defaultLang);
    } else {
      changeLanguage(lang);
    }

    const headerBox = document.getElementById("header-box");
    if (headerBox) {
      document.body.classList.remove("overflow-hidden");
      headerBox.classList.remove("relative");
      headerBox.classList.remove("relative");
    }
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <Routes />
      {loading ? <Loaders /> : null}
      {snackbarObj?.snackbarOpen ? (
        <Snackbars
          snackBarMessage={snackbarObj?.snackbarMessage}
          snackbarOpen={snackbarObj?.snackbarOpen}
          snackBarState={snackbarObj?.snackbarState}
        />
      ) : null}
    </ThemeProvider>
  );
}

export default App;
